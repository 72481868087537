<template>
  <div class="introductionMain">
    <v-head :txt="specs !=1?'云证书API集成服务( 证书申请 )':'云证书API集成服务( 实名+证书申请 )'" />
    <div class="cloudBox">
      <div class="cloudCrumbs">
        <router-link :to="{name:'businessCooperation'}">
          业务合作
        </router-link>
        <span>></span>
        <el-button type="text" :class="{crumbsActive:cloudSteps===0}" @click="changeSteps">{{specs != 1 ?'云证书API集成服务( 证书申请 )':'云证书API集成服务( 实名+证书申请 )'}}</el-button>
        <span v-if="cloudSteps>=1">></span>
        <el-button type="text" class="crumbsActive" v-if="cloudSteps>0">业务申请</el-button>
      </div>
      <v-introduction v-if="cloudSteps===0" />
      <v-information v-if="cloudSteps===1" :productId="productId" />
      <v-paymentInfo v-if="cloudSteps===2" :detailInfo="detailInfo" :detailApiList="detailApiList" />
    </div>
  </div>
</template>

<script>
import head from './components/head.vue'
import paymentInfo from './paymentInfo.vue'
import information from './information.vue'
import introduction from './introduction.vue'
export default {
  data() {
    return {
      cloudSteps: 0,
      detailInfo: {},
      detailApiList: [],
      productId: '',
      specs: 0
    }
  },
  components: {
    'v-head': head,
    'v-introduction': introduction,
    'v-information': information,
    'v-paymentInfo': paymentInfo
  },
  mounted() {
    console.log(this.specs, typeof this.specs)
    this.specs = this.$route.query.specs
    if (this.$store.state.user.orderNo !== '') {
      this.getDetails()
    }
  },
  methods: {
    changeSteps() {
      this.$store.commit('user/setOrderNo', '')
      this.$nextTick(() => {
        this.cloudSteps = 0
      })
    },
    getDetails() {
      this.$api
        .getCloudDetail({ orderNo: this.$store.state.user.orderNo })
        .then(res => {
          this.detailInfo = res.data
          if (res.data.status === 2) {
            this.cloudSteps = 2
          }
          this.detailApiList = res.data.interfaceNames
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.introductionMain {
  min-height: calc(100vh - 60px);
  background: #f5f8fb;
}
.cloudBox {
  width: 1300px;
  margin: 60px auto 0;
}
</style>