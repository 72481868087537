<template>
  <div class="productInfoBox" v-loading="isLoad">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="92px" label-position="left">
      <div class="productInfo">
        <h2>产品信息</h2>
        <el-form-item label="产品名称" prop="name">
          <p>{{specs != 1 ?'云证书API集成服务( 证书申请 )':'云证书API集成服务( 实名+证书申请 )'}}</p>
        </el-form-item>
        <el-form-item label="规格" prop="name">
          <p>{{specs != 1 ?'纯API版':'网页版'}}</p>
        </el-form-item>
        <el-form-item label="申请权限" prop="authority">
          <el-button plain @click="displayConfig" size="small" class="powerConfig el-icon-arrow-down">接口权限配置</el-button>
          <el-tree :data="treeData" ref="tree" show-checkbox node-key="id" default-expand-all v-show="configShow"></el-tree>
        </el-form-item>
      </div>
      <div class="productInfo">
        <h2>集成企业</h2>
        <el-table :data="customerList" style="width: 100%" @row-click="getRowInfo">
          <el-table-column prop="select" label="选择" width="80">
            <template slot-scope="scope">
              <el-radio v-model="selectId" :label="scope.row.appId"></el-radio>
            </template>
          </el-table-column>
          <el-table-column prop="appId" label="API认证账号（AppId）"></el-table-column>
          <el-table-column prop="name" label="企业名称"></el-table-column>
          <el-table-column prop="identNo" label="统一社会信用代码"></el-table-column>
          <el-table-column prop="legalPersonName" label="法人姓名"></el-table-column>
        </el-table>
        <p class="newEnterprise">
          <el-button type="text" @click="completeInfo(false)">使用新企业</el-button>
        </p>
      </div>
      <div class="submitApplication">
        <el-button type="primary" @click="submitOrder">提交申请</el-button>
      </div>
    </el-form>

    <entryInfo ref="entryInfo" :isPersonAuth="isPersonAuth" />
  </div>
</template>

<script>
import entryInfo from '@/views/userInfo/enterprise/entryInfo.vue'
export default {
  data() {
    return {
      isLoad: false,
      ruleForm: {
        name: '云证书API集成服务( 实名+证书申请 )',
        authority: {}
      },
      selectId: '',
      configShow: false,
      customerList: [],
      treeData: [],
      rules: {
        name: [
          { required: true, message: '请选择产品名称', trigger: 'change' }
        ],
        authority: [
          { required: true, message: '请选择申请权限', trigger: 'change' }
        ]
      },
      enterpriseInfo: '',
      specs: '',
      isPersonAuth: ''
    }
  },
  props: ['productId'],
  components: {
    entryInfo
  },
  mounted() {
    this.specs = this.$route.query.specs
    this.getCloudList()
    this.getEnterprise()
  },
  methods: {
    completeInfo(item) {
      this.isPersonAuth = item
      this.$refs.entryInfo.realNameVisible = true
    },
    getCloudList() {
      this.isLoad = true
      this.$api.getCloudCertList({ specs: this.specs }).then(res => {
        this.isLoad = false
        this.treeData = this.treeDataTranslate(res.data.cloudCertAPI)
      })
    },
    getEnterprise() {
      this.isLoad = true
      this.$api
        .getCustomerByCondition({ authStatus: 1 })
        .then(res => {
          this.isLoad = false
          this.customerList = res.data.customerList
          if (res.data.customerList.length > 0) {
            this.selectId = res.data.customerList[0].appId
            this.enterpriseInfo = res.data.customerList[0]
          }
        })
        .catch(() => {
          this.isLoad = false
        })
    },
    treeDataTranslate(data) {
      let orgCertData = []
      for (let i = 0; i < data.orgCert.length; i++) {
        orgCertData.push({
          id: data.orgCert[i].id,
          label: data.orgCert[i].name
        })
      }
      let personCertData = []
      for (let i = 0; i < data.personCert.length; i++) {
        personCertData.push({
          id: data.personCert[i].id,
          label: data.personCert[i].name
        })
      }
      var orgCert = {
        label: '企业证书',
        children: orgCertData
      }
      var personCert = {
        label: '个人证书',
        children: personCertData
      }
      var cloudCertAPI = [
        {
          label: '接口配置',
          children: [personCert, orgCert]
        }
      ]
      return cloudCertAPI
    },
    displayConfig() {
      this.configShow = !this.configShow
    },
    newEnterprise() {
      this.$bus.$off('afterLogin')
      this.$bus.$on('afterLogin', () => {})
      this.$api.getAccountInfo().then(res => {
        if (res.data.verified) {
          this.completeInfo(false)
        } else {
          this.$confirm(
            '当前账号未完成【管理员认证】，实名之后才可办理业务。',
            '提示',
            {
              confirmButtonText: '实名认证',
              cancelButtonText: '暂不认证',
              type: 'warning'
            }
          )
            .then(() => {
              this.$router.push({ name: 'personHome' })
            })
            .catch(() => {})
        }
      })
    },
    getRowInfo(row) {
      this.enterpriseInfo = row
    },
    submitOrder() {
      if (this.enterpriseInfo.appId === undefined) {
        this.$confirm('该企业未创建AppId，请解绑企业重新认证', '提示', {
          confirmButtonText: '去解绑',
          cancelButtonText: '暂不解绑',
          type: 'warning'
        })
          .then(() => {
            this.$router.push({ name: 'enterprise' })
          })
          .catch(() => {})
        return false
      }
      const checkedKeys = this.$refs.tree.getCheckedKeys()
      let apiList = []
      for (let i = 0; i < checkedKeys.length; i++) {
        if (checkedKeys[i] !== undefined) {
          apiList.push(checkedKeys[i])
        }
      }
      if (apiList.length <= 0) {
        this.$message.error('请选择接口权限配置')
      } else {
        if (this.enterpriseInfo === '') {
          this.$message.error('请选择集成企业')
        } else {
          this.isLoad = true
          let data = {
            productId: this.productId,
            apiList: apiList.toString(),
            appId: this.enterpriseInfo.appId,
            productType: 1 //产品类型（0：网络身份 1：云证书）
          }
          this.$api
            .setOrderSubmit(data)
            .then(res => {
              this.isLoad = false
              if (res.code === '0') {
                this.$router.push({ name: 'serviceCenter', query: { type: 2 } })
              }
            })
            .catch(() => {
              this.isLoad = false
            })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.productInfoBox {
  padding: 8px 24px 0;
  background: #fff;
}
.productInfo {
  &:nth-child(2) {
    margin-top: 23px;
  }
  h2 {
    font-size: 14px;
    font-weight: 700;
    color: #303b50;
    line-height: 19px;
    position: relative;
    border-bottom: 1px solid #dee2e8;
    padding: 16px 12px;
    margin-bottom: 24px;
    &::before {
      position: absolute;
      width: 3px;
      height: 16px;
      content: '';
      background: #1c67f3;
      top: 16px;
      left: 0;
    }
  }
  .powerConfig {
    width: 510px;
    background: #f2f3f9;
    border-radius: 4px;
    border: 1px solid #d5dbe2;
    font-size: 14px;
    line-height: 1;
    padding-left: 8px;
    text-align: left;
    cursor: pointer;
    position: relative;
    ::v-deep span {
      color: #1c67f3;
    }
  }
  .el-icon-arrow-down:before {
    position: absolute;
    right: 8px;
    top: 10px;
  }
  .el-tree {
    width: 510px;
    padding: 9px 16px 24px;
    border-radius: 0 0 4px 4px;
    border: 1px solid #d5dbe2;
    border-top: none;
    margin-top: -4px;
  }
  ::v-deep .el-table tr > th {
    background: #f2f3f9;
    font-weight: normal;
  }
  ::v-deep .el-table th {
    padding: 9px 0;
  }
  ::v-deep .el-table td {
    padding: 13px 0;
    border-color: #dee0e9;
  }
  ::v-deep .el-radio__label {
    display: none;
  }
  .newEnterprise {
    padding: 4px 0;
    border-bottom: 1px solid #dee0e9;
    text-align: center;
  }
}
.submitApplication {
  padding: 57px;
  text-align: center;
  .el-button {
    width: 150px;
    padding: 11px 20px;
  }
}
</style>