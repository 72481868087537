<template>
  <div v-loading="detailsLoad">
    <div class="introductionInfo">
      <h2>{{specs != 1?'云证书API集成服务( 证书申请 )':'云证书API集成服务( 实名+证书申请 )'}}</h2>
      <h5>
        <svg-icon icon-class="notCertified" />限企业实名用户使用
      </h5>
      <p>{{cloudDetails.description}}</p>
      <el-button type="primary" @click="applyAccess">立即接入</el-button>
      <p :class="{animation:animation}">
        <el-checkbox v-model="checked"></el-checkbox>同意<i>《云证书API集成服务协议》</i>
      </p>
    </div>
    <div class="commonMainArea productRelated">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="服务对象" name="serviceObject">
          <div class="productIntroduction">
            <h2>服务对象</h2>
            <pre>{{cloudDetails.serviceDesc}}</pre>
          </div>
        </el-tab-pane>
        <el-tab-pane label="产品详情" name="productIntroduction">
          <div class="productIntroduction">
            <h2>产品详情</h2>
            <pre>{{cloudDetails.description}}</pre>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checked: false,
      detailsLoad: false,
      animation: false,
      cloudDetails: '',
      activeName: 'serviceObject',
      type: '',
      specs: ''
    }
  },
  mounted() {
    this.type = this.$route.query.type
    this.specs = this.$route.query.specs
    this.getDetails()
  },
  methods: {
    handleClick() {},
    getDetails() {
      this.detailsLoad = true
      let data = {
        productType: 1,
        type: this.type,
        specs: this.specs
      }
      this.$api
        .getByIdDetails(data)
        .then(res => {
          this.detailsLoad = false
          this.cloudDetails = res.data
          this.$parent.productId = res.data.id
        })
        .catch(() => {
          this.detailsLoad = false
        })
    },
    verify() {
      this.$bus.$off('afterLogin')
      this.$bus.$on('afterLogin', () => {})
      this.$api.getAccountInfo().then(res => {
        if (res.data.verified) {
          this.enterpriseStatus(res.data.bindCustomer)
        } else {
          this.$confirm(
            '此业务需要【管理员认证】和【企业认证】成功之后才可申请。',
            '提示',
            {
              confirmButtonText: '去认证',
              cancelButtonText: '暂不认证',
              type: 'warning'
            }
          )
            .then(() => {
              this.$router.push({ name: 'account' })
            })
            .catch(() => {})
        }
      })
    },
    enterpriseStatus(bindCustomer) {
      if (bindCustomer) {
        this.$parent.cloudSteps = 1
      } else {
        this.$confirm('此业务需要【企业认证】成功之后才可申请。', '提示', {
          confirmButtonText: '去认证',
          cancelButtonText: '暂不认证',
          type: 'warning'
        })
          .then(() => {
            this.$router.push({ name: 'enterprise' })
          })
          .catch(() => {})
      }
    },
    applyAccess() {
      if (this.checked) {
        this.verify()
      } else {
        this.hintAnimation()
      }
    },
    hintAnimation() {
      this.animation = true
      setTimeout(() => {
        this.animation = false
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
.introductionInfo {
  padding: 40px 42px;
  background: #fff;
  h2 {
    font-size: 18px;
    color: #303b50;
    line-height: 24px;
  }
  h5 {
    font-size: 12px;
    color: #1c67f3;
    line-height: 20px;
    margin: 12px 0 12px;
    font-weight: normal;
    vertical-align: -0.25em;
  }
  .svg-icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
  p {
    width: 950px;
    font-size: 14px;
    color: #303b50;
    line-height: 28px;
  }
  .el-button {
    margin: 24px 0 27px;
  }
  .el-checkbox {
    margin-right: 8px;
  }
  i {
    font-size: 14px;
    color: #1c67f3;
    line-height: 19px;
    letter-spacing: 1px;
  }
}
.productRelated {
  margin-top: 18px;
  padding: 12px 40px 120px;
  background: #fff;
  ::v-deep .el-tabs__header {
    margin: 0;
  }
}
.productIntroduction {
  h2 {
    font-size: 18px;
    font-weight: bold;
    color: #303b50;
    line-height: 20px;
    margin: 40px 0 14px;
  }
  p {
    font-size: 14px;
    color: #303b50;
    line-height: 28px;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
@keyframes shake {
  0%,
  100% {
    transform: translate(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate(-8px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translate(8px);
  }
}
.animation {
  animation: shake 0.5s;
}
</style>