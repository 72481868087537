<template>
  <div class="networkIdentity">
    <div class="networkBox">
      <div v-loading="detailLoad">
        <div class="paymentInfoBox">
          <div class="paymentInfo">
            <h2>付款信息</h2>
            <h5><i>*</i>产品名称<span>{{detailInfo.productName}}</span></h5>
            <h5><i>*</i>规格<span>{{Number(detailInfo.productSpecs)===0?'纯API版':'网页版'}}</span></h5>
            <div class="authAccount clearfix">
              <p><span>认证账号（AppId）：</span>{{detailInfo.appId}}</p>
              <p><span>申请主体：</span>{{detailInfo.companyName}}</p>
              <p><span>订单号：</span>{{detailInfo.orderNo}}</p>
              <p><span>申请时间：</span>{{detailInfo.createdDate}}</p>
              <p><span>业务状态：</span><u>{{detailInfo.status|orderStatus}}</u></p>
              <p><span>接口权限：</span><i v-for="item of detailApiList" :key="item">{{item}}</i></p>
              <p><span>审核意见：</span>{{detailInfo.operationComment}}</p>
              <p><span>审核时间：</span>{{detailInfo.operationTime||'- -'}}</p>
            </div>
          </div>
          <div class="paymentMethod">
            <h2>付款信息</h2>
            <h5>此业务仅支持银行汇款</h5>
            <h4><svg-icon icon-class="unionPay" />银行汇款</h4>
            <div class="remittanceAccount">
              <h3>请汇款至以下账户</h3>
              <ul>
                <li><span>账户名称：</span>山东豸信认证服务有限公司</li>
                <li><span>开户银行：</span>中国农业银行济南和平支行</li>
                <li><span>银行账号：</span>15158101040030989</li>
              </ul>
              <p>汇款完成后，请在订单页面提交打款信息，提交后最快1小时内可到账。</p>
              <p>若遇到问题请联系客服，客服电话：400-030-2013。</p>
            </div>
          </div>
          <div class="submitApplication">
            <el-button type="primary" @click="submitInfo">提交打款信息</el-button>
          </div>
        </div>
        <el-dialog title="提交打款信息" :visible.sync="remittanceVisible" width="30%" :before-close="handleClose" class="paymentInformation">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
            <el-form-item label="汇款方名称（企业全称）" prop="remittanceName">
              <el-input v-model="ruleForm.remittanceName" size="small" maxlength="60"></el-input>
            </el-form-item>
            <el-form-item label="汇款银行账号" prop="remittanceAccount">
              <el-input v-model="ruleForm.remittanceAccount" size="small" maxlength="30"></el-input>
            </el-form-item>
            <el-form-item label="汇款日期" prop="remittanceDate">
              <el-date-picker v-model="ruleForm.remittanceDate" placeholder="选择日期" :picker-options="pickerOptions" value-format="yyyy-MM-dd HH:mm:ss" type="date" size="small">
              </el-date-picker>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="handleClose" size="small">取 消</el-button>
            <el-button type="primary" @click="paymentSave" size="small">确定提交</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { orderStatus, orderType, productSpecs, productType } from '@/utils/typeMatch';
export default {
  data () {
    return {
      detailLoad: false,
      remittanceVisible: false,
      ruleForm: {
        remittanceName: '',
        remittanceAccount: '',
        remittanceDate: ''
      },
      rules: {
        remittanceName: [
          { required: true, message: '请填写正确的汇款方名称（企业全称）', trigger: 'change' }
        ],
        remittanceAccount: [
          { required: true, message: '请填写正确的汇款银行账号', trigger: 'change' }
        ],
        remittanceDate: [
          { required: true, message: '请选择正确的汇款日期', trigger: 'change' }
        ],
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '今天',
          onClick (picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick (picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick (picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
    }
  },
  props: ['detailInfo', 'detailApiList'],
  filters: {
    orderType,
    productType,
    orderStatus,
    productSpecs,
  },
  mounted () {
  },
  methods: {
    handleClose () {
      this.remittanceVisible = false
      this.ruleForm.remittanceName = ''
      this.ruleForm.remittanceAccount = ''
      this.ruleForm.remittanceDate = ''
      this.$refs['ruleForm'].resetFields()
    },
    submitInfo () {
      this.remittanceVisible = true
    },
    paymentSave () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.paymentInfoSubmit()
        }
      })
    },
    paymentInfoSubmit () {
      let data = {
        orderNo: this.$store.state.user.orderNo,
        remitName: this.ruleForm.remittanceName,
        remitBankNo: this.ruleForm.remittanceAccount,
        remitDate: this.ruleForm.remittanceDate
      }
      this.$api.setPaymentSave(data).then(res => {
        this.isLoad = false
        this.remittanceVisible = false
        if (res.code === '0') {
          this.$alert('提交打款信息成功', '提示', {
            confirmButtonText: '确定',
            type: 'success',
            callback: () => {
              this.$router.push({ name: 'integration' })
            }
          });
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.networkIdentity {
  min-height: 100vh;
}
.networkBox {
  width: 1300px;
  background: #f5f8fb;
}
.paymentInfoBox {
  padding: 8px 24px 0;
  background: #fff;
  h2 {
    font-size: 14px;
    font-weight: 700;
    color: #303b50;
    line-height: 19px;
    position: relative;
    border-bottom: 1px solid #dee2e8;
    padding: 16px 12px;
    &::before {
      position: absolute;
      width: 3px;
      height: 16px;
      content: '';
      background: #1c67f3;
      top: 16px;
      left: 0;
    }
  }
}
.paymentInfo {
  h5 {
    width: 33%;
    display: inline-block;
    padding: 24px 0;
    font-size: 14px;
    color: #303b50;
    line-height: 19px;
    font-weight: normal;
    i {
      font-size: 14px;
      font-weight: 400;
      color: #ff5846;
      line-height: 19px;
      margin-right: 8px;
    }
    span {
      margin-left: 24px;
    }
  }
  h4 {
    width: 114px;
    height: 38px;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #1c67f3;
    font-size: 14px;
    color: #303b50;
    line-height: 19px;
    letter-spacing: 1px;
  }
}
.authAccount {
  width: 1252px;
  background: #f1f4fc;
  border-radius: 4px;
  padding: 20px 16px;
  p {
    font-size: 14px;
    color: #303b50;
    line-height: 19px;
    margin-bottom: 22px;
    float: left;
    &:nth-child(2n + 1) {
      width: 33%;
    }
    &:nth-child(2n) {
      width: 66%;
    }
    &:nth-child(6) {
      margin-bottom: 14px;
    }
    &:last-child,
    &:nth-last-child(2) {
      margin-bottom: 0;
    }
  }
  span {
    color: #6c737b;
  }
  u {
    display: inline-block;
    width: 62px;
    height: 24px;
    background: #fef8ec;
    border-radius: 15px;
    border: 1px solid #fbbc4d;
    font-size: 12px;
    color: #e89e1c;
    line-height: 24px;
    text-decoration: none;
    text-align: center;
  }
  i {
    display: inline-block;
    height: 24px;
    padding: 3px 8px;
    background: #dbe4ff;
    border-radius: 1px;
    border: 1px solid #a9beff;
    font-size: 12px;
    color: #1c67f3;
    line-height: 17px;
    margin-right: 8px;
    margin-bottom: 8px;
  }
}
.paymentMethod {
  margin-top: 23px;
  h5 {
    font-size: 14px;
    color: #929292;
    line-height: 19px;
    letter-spacing: 1px;
    font-weight: normal;
    padding: 16px 0;
  }
  h4 {
    width: 114px;
    height: 38px;
    line-height: 38px;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #1c67f3;
    font-size: 14px;
    color: #303b50;
    letter-spacing: 1px;
    font-weight: normal;
    padding: 0 14px;
    margin-bottom: 24px;
    background: url('~@/assets/image/personCenter/selected.png') 98px 23px
      no-repeat;
    background-size: 14px;
    cursor: pointer;
  }
  .svg-icon {
    margin-right: 8px;
  }
}
.remittanceAccount {
  width: 678px;
  height: 316px;
  background: #f1f4fc;
  padding: 30px;
  h3 {
    font-size: 16px;
    color: #303b50;
    line-height: 21px;
    letter-spacing: 1px;
    font-weight: normal;
  }
  ul {
    margin: 24px 0;
    width: 614px;
    height: 137px;
    background: #ffffff;
    border-left: 2px solid #1c67f3;
    padding: 24px 22px;
  }
  li {
    font-size: 14px;
    color: #303b50;
    line-height: 19px;
    list-style: none;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  span {
    color: #6c737b;
  }
  p {
    font-size: 14px;
    color: #6c737b;
    line-height: 19px;
    margin-bottom: 8px;
  }
}
.submitApplication {
  padding: 40px 0;
  .el-button {
    width: 150px;
    padding: 11px 20px;
  }
}
.paymentInformation {
  ::v-deep .el-dialog__header {
    margin: 0 24px;
    padding: 12px 0;
    border-bottom: 1px solid #dee2e8;
    span {
      font-weight: 700;
      font-size: 14px;
      color: #303b50;
      line-height: 19px;
    }
  }
  ::v-deep .el-dialog__headerbtn {
    top: 15px;
  }
  ::v-deep .el-dialog__body {
    padding: 21px 24px 25px;
  }
  ::v-deep .el-form-item {
    margin-bottom: 28px;
  }
  ::v-deep .el-form-item__label {
    float: none;
    display: inline-block;
    font-size: 14px;
    color: #303b50;
    line-height: 19px;
    padding-bottom: 14px;
  }
  ::v-deep .el-form-item__content {
    line-height: 32px;
  }
  ::v-deep .el-date-editor.el-input {
    width: 100%;
  }
}
</style>